/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useState } from "react";
import "./WalletInfo.scss";

import blockchainIcon from "./../../assets/blockchain.png";

const WalletInfo = ({ user }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseOver = () => {
        setShowTooltip(true);
    };

    const handleMouseOut = () => {
        setShowTooltip(false);
    };

    const sepoliaNetworkConnected = "Sepolia test network.";

    return (
        <div className="WalletInfo">
            <div className="tooltip" onMouseLeave={handleMouseOut}>
                <img
                    src={blockchainIcon}
                    alt="icon"
                    className="tooltip-icon"
                    onClick={handleMouseOver}
                />
                {showTooltip && (
                    <span className="tooltip-text">
                        <div>Connected address: {user?.publicAddress}</div>

                        <div className="">
                            Connected to network:{" "}
                            {!!user.publicAddress
                                ? sepoliaNetworkConnected
                                : user.connectedNetwork +
                                  ". Please, switch to the Sepolia test network!"}
                        </div>
                    </span>
                )}
            </div>
        </div>
    );
};

WalletInfo.propTypes = {};

WalletInfo.defaultProps = {};

export default WalletInfo;
