/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect, useState } from "react";
import { SEPOLIA_CHAIN_ID } from "../../utils/utils";
import "./ConnectWallet.scss";

const ConnectWallet = ({ label, style, onSubmit }) => {
    // console.log("ConnectWallet component");
    type User = {
        publicAddress: string;
        connectedNetwork: string;
    };

    const initUser: User = {
        publicAddress: "",
        connectedNetwork: "",
    };

    const { ethereum } = window;

    const [user, setUser] = useState(initUser);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [buttonStyle, setButtonStyle] = useState("");

    let buttonLabel = "";
    let tempButtonStyle = "";
    const buttonClassBase = "cta-button ";
    const buttonClassDefault = "connect-wallet-button";
    const buttonClassConnected = " connected";
    const buttonClassNotAuthorized = " not-authorized";
    const buttonClassHide = "hide";

    if (typeof label !== "undefined") {
        buttonLabel = label;
    } else {
        buttonLabel = "Connect wallet";
    }
    if (typeof style === "undefined") {
        style = buttonClassDefault;
    }
    tempButtonStyle = buttonClassBase + style;

    const isTestUser = (currentUserAccount) => {

        console.log(currentUserAccount.toLowerCase())
        console.log(process.env.REACT_APP_TEST_USER_PUBLIC_KEY?.toLowerCase())
        return (
            currentUserAccount.toLowerCase() ===
            process.env.REACT_APP_TEST_USER_PUBLIC_KEY?.toLowerCase()
        );
    };

    const checkIfKnownUser = (currentUserAccount): boolean => {
        const isUserRecognized = isTestUser(currentUserAccount);
        if (isUserRecognized) {
            // console.log("Account recognized");
            tempButtonStyle += buttonClassConnected;
            setButtonStyle(tempButtonStyle);
        } else {
            console.log("Unknown account");
            tempButtonStyle += buttonClassNotAuthorized;
        }
        setButtonStyle(tempButtonStyle);

        return isUserRecognized;
    };

    const manageEthereumObject = (): boolean => {
        if (ethereum) {
            // console.log("We have the ethereum object", ethereum);
            return true;
        } else {
            console.log(
                "You need to install the Metamask extension on your browser."
            );

            setButtonStyle(buttonClassHide);
            return false;
        }
    };

    const getAccount = async (): Promise<string> => {
        // console.log("getAccount");

        const accounts = await ethereum.request({ method: "eth_accounts" });
        return new Promise((resolve) => {
            resolve(manageAccount(accounts));
        });
    };

    const requestAccount = async (): Promise<string> => {
        // console.log("requestAccount");

        const accounts = await ethereum.request({
            method: "eth_requestAccounts",
        });

        return new Promise((resolve) => {
            resolve(manageAccount(accounts));
        });
    };

    const manageAccount = (accounts): string => {
        // console.log(accounts);

        if (accounts.length === 0) {
            // Wallet not connected
            setButtonStyle(tempButtonStyle);
            return "";
        } else {
            return accounts[0];
        }
    };

    const checkNetwork = async (): Promise<string> => {
        let chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId !== SEPOLIA_CHAIN_ID) {
            console.log("You have to connect to the Sepolia Test Network.");
            setButtonStyle(tempButtonStyle);
        } else {
            //console.log("Connected to Sepolia Test Network " + chainId);
        }
        return chainId;
    };

    const checkIfWalletIsConnected = async () => {
        if (!manageEthereumObject()) {
            return;
        }

        // Test connection to wallet
        let currentUserAccount = await getAccount();
        if (
            currentUserAccount === "" ||
            !checkIfKnownUser(currentUserAccount)
        ) {
            // Exit when user not recognized
            return;
        }

        setIsAuthenticated(isTestUser(currentUserAccount));

        let chainId = await checkNetwork();

        // Memorizing the user
        setUser({
            publicAddress: currentUserAccount,
            connectedNetwork: chainId,
        });
        // Sending info to parent
        onSubmit({
            publicAddress: currentUserAccount,
            connectedNetwork: chainId,
        });
    };

    const connectWallet = async () => {
        try {
            console.log("connectWallet");

            if (!manageEthereumObject()) {
                return;
            }
            requestAccount();

            let currentUserAccount = await getAccount();
            if (
                currentUserAccount === "" ||
                !checkIfKnownUser(currentUserAccount)
            ) {
                // Exit when user not recognized
                return;
            }

            setIsAuthenticated(isTestUser(currentUserAccount));

            let chainId = await checkNetwork();

            // Memorizing the user
            setUser({
                publicAddress: currentUserAccount,
                connectedNetwork: chainId,
            });
            // Sending info to parent
            onSubmit({
                publicAddress: currentUserAccount,
                connectedNetwork: chainId,
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        checkIfWalletIsConnected();
    }, []);

    /**
     * Reloads page when connection changes to trigger a new evaluation
     */
    useEffect(() => {
        if (ethereum) {
            ethereum.on("chainChanged", () => {
                window.location.reload();
            });
            ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
        }
    }, []);

    return (
        <div className="ConnectWallet">
            <button onClick={connectWallet} className={buttonStyle}>
                {buttonLabel}
            </button>
        </div>
    );
};

ConnectWallet.propTypes = {};

ConnectWallet.defaultProps = {};

export default ConnectWallet;
