/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React from "react";
import "./AppFooter.scss";
import ConnectWallet from "../ConnectWallet/ConnectWallet.tsx";

const AppFooter = ({ onSubmit }) => {

    const getConnectedUserCallBack = (user) => {
        onSubmit(user);
    }

    return (
        <div className="AppFooter">
            <footer className="core-width">
                <div>
                    <p>Beligent ©2023</p>
                    <p>Zurich, Switzerland</p>
                </div>
                <div>
                    <p>contact(at)beligent.io</p>
                    <div>
                        <ConnectWallet
                            label="private"
                            style="no-style-button"
                            onSubmit={getConnectedUserCallBack}
                        />
                    </div>
                </div>
            </footer>
        </div>
    );
};

AppFooter.propTypes = {};

AppFooter.defaultProps = {};

export default AppFooter;
