/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React from "react";
import { Link } from "react-router-dom";
import "./AppHeader.scss";

import WalletInfo from "./../WalletInfo/WalletInfo.js";
// import { isSepoliaNetworkConnected } from "../../utils/utils";

const AppHeader = ({ user }) => {
    /*
    function Menu() {
        return (
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/validator">Validator</Link>
                    </li>
                    <li>
                        <Link to="/certificates">Certificates</Link>
                    </li>
                    <li>
                        <Link to="/nothing-here">404</Link>
                    </li>
                </ul>
            </nav>
        );
    }
    */

    return (
        <div className="AppHeader">
            <header>
                <div className="core-width">
                    <Link to="./">
                        <div>
                            <p className="brand-name">beligent.io</p>
                        </div>
                    </Link>
                    <div className="">
                        <span className="small-text menu-container">
                            SITE UNDER CONSTRUCTION
                        </span>
                        
                        {/* <Menu /> */}
                    </div>
                </div>
            </header>

            {(() => {
                if (user.publicAddress) {
                    return <WalletInfo user={user} />;
                }
            })()}
        </div>
    );
};

AppHeader.propTypes = {};

AppHeader.defaultProps = {};

export default AppHeader;
