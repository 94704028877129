/**
 * Beligent
 * Copyright 2023
 */

import React, { useState } from "react";
import "./App.scss";

import AppHeader from "./components/AppHeader/AppHeader";
import AppFooter from "./components/AppFooter/AppFooter";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import MainContent from "./components/MainContent/MainContent";
import Validator from "./components/Validator/Validator/Validator.tsx";
import Portal from "./components/Portal/Portal.tsx";
import { useLocation } from "react-router-dom";

/**
 * @function NoMatch
 * @description Renders the 404 page when the user tries to access a page that does not exist
 * @returns {JSX.Element} The 404 page
 */
function NoMatch() {
    return (
        <div className="screen-404">
            <span className="main-title blue-title very-large-text">
                Page not found
            </span>
            <p>
                <Link to="/">Back to home page</Link>
            </p>
        </div>
    );
}

function App() {
    const location = useLocation();
    const initUser = {
        publicAddress: "",
        connectedNetwork: "",
    };

    const [currentUser, setCurrentUser] = useState(initUser);

    const getConnectedUserCallBack = (user) => {
        if (
            (!currentUser || !currentUser.publicAddress) &&
            user &&
            user.publicAddress !== ""
        ) {
            // console.log("User from connect App.js: ");
            setCurrentUser(user);
        }
    };

    // This is temporary as we don't retrieve the user's nfts on the blockchain.
    // We assume it is empty if page reloaded.
    // probably not used anymore but could be useful.
    // localStorage.setItem('_beligent_minting', JSON.stringify(false));
    // localStorage.setItem('_beligent_minted', JSON.stringify(false));

    function Layout() {
        return (
            <div className="app-container">
                <AppHeader user={currentUser} />
                <Outlet />
                <AppFooter onSubmit={getConnectedUserCallBack} />
            </div>
        );
    }

    return (
        <div className="App">
            {/* <div>You are now at {location.pathname}</div> */}
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<MainContent user={currentUser} />} />
                    <Route
                        path="/validator/"
                        element={<Validator user={currentUser} />}
                    />
                    {currentUser && (
                        <Route
                            path="/portal/"
                            element={<Portal user={currentUser} />}
                        />
                    )}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
