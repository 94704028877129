/**
 * Beligent
 * Copyright 2023
 */


export const SEPOLIA_CHAIN_ID = "0xaa36a7"; // todo, don't export, use function instead
export const IPFS_PROTOCOL_PREFIX = "ipfs://";
const SVG_PREFIX = "data:image/svg+xml;utf8,<svg xmlns=";


export function isIpfsUrl(url) {
    let ok = false;
    if (url) {
        ok = url.startsWith(IPFS_PROTOCOL_PREFIX);
        ok = ok || url.startsWith(SVG_PREFIX);
        ok = ok || url.startsWith("https://alchemy.mypinata.cloud/ipfs/");
    } 
    return ok;
}

export function isSvg(metadataImage) {
    return metadataImage.startsWith(SVG_PREFIX);
}


export const isSepoliaNetworkConnected = (userNetwork) => {
    return userNetwork === SEPOLIA_CHAIN_ID;
};



// Could be used later
// export function copyToClipboard2(targetId) {
//     var copyText = document.getElementById(targetId);
  
//     copyText.select();
//     copyText.setSelectionRange(0, 99999); // For mobile devices
  
//      // Copy the text inside the text field
//     navigator.clipboard.writeText(copyText.value);
  
//     // Alert the copied text
//     alert("Copied the text: " + copyText.value);
//   }

//   /**
//  * Returns the content of the specified element.
//  * @param string elementToCopy 
//  */
// export function copyToClipboard(elementIdToCopy, elementIdToNotifyOutcome) {
//     console.log('Copy to clipboard');
//     let contentToCopy = document.getElementById(elementIdToCopy).innerHTML;

//     contentToCopy = contentToCopy.replaceAll('<br>', '\n',);
//     // The DOMparser allows to save HTML entities from conversion
//     const doc = new DOMParser().parseFromString(contentToCopy, "text/html");
//     contentToCopy = doc.documentElement.textContent;

//     navigator.clipboard.writeText(contentToCopy).then(function () {
//         document.getElementById(elementIdToNotifyOutcome).innerHTML = 'Text copied!';
//         // displaySuccess('Text copied!');
//         return true;
//     }, function (error) {
//         console.log('Sorry, did not work. Error: ', error);
//     });
// }