/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useState } from "react";
import "./ContactButton.scss";

/**
 * ContactButton component.
 * A button that when clicked, copies the email address to the clipboard.
 */
const ContactButton = () => {
    const [imageVisible, setImageVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const emailUser = "contact";
    const emailDomain = "beligent";

    /**
     * Copies the email address to the clipboard.
     * @returns {void}
     */
    const handleCopyEmail = () => {
        const email = `${emailUser}@${emailDomain}.io`;

        setImageVisible(false);
        setTimeout(() => setImageVisible(true), 500);

        navigator.clipboard.writeText(email).then(
            () => {
                setTimeout(() => setShowConfirmation(true), 500);
            },
            (err) => {
                console.error("Could not copy email: ", err);
            }
        );
    };

    return (
        <div className="contact-button">
            <div className="content-row">
                <div className="block-center">
                    <p className="center">
                        Join the certification revolution!
                    </p>
                    <p className="center">
                        Secure your items with our blockchain-powered
                        certificates.
                    </p>
                    <div className="center center-left-padding button-container">
                        <div className="">
                            <button
                                onClick={handleCopyEmail}
                                className="tooltip-button beligent-btn btn-semi-transparent btn-glow"
                            >
                                Contact us
                            </button>
                            {imageVisible && (
                                <img
                                    src="small-green-check.png"
                                    width="40"
                                    alt=""
                                    className="appear-slowly small-check"
                                />
                            )}
                            {!imageVisible && (
                                <img
                                    src="pixel.png"
                                    width="40"
                                    alt=""
                                    className="appear-slowly small-check"
                                />
                            )}
                        </div>
                    </div>
                    {showConfirmation && (
                        <div className="center very-small-text confirmation">
                            Our address has been copied to your clipboard.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ContactButton.propTypes = {};

ContactButton.defaultProps = {};

export default ContactButton;
