/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2023
 */

import React, { useRef, useState, useEffect } from "react";
import "./InputZone.scss";
import { useNavigate, useLocation } from "react-router-dom";

const InputZone = ({ onSubmitCallback }) => {
    // console.log("InputZone component");
    // console.log("parameterUrl: " + parameterUrl);

    const inputName = useRef<HTMLInputElement>(null);
    const [inputContent, setInputContent] = useState("");
    const [query, setQuery] = useState("");

    const location = useLocation();
    const history = useNavigate();

    useEffect(() => {
        // console.log("useEffect inputzone");
        const params = new URLSearchParams(location.search);
        const paramValue = params.get("opensea-link") || "";

        if (!!paramValue) {
            document
                .getElementsByClassName("input-container")[0]
                ?.classList.add("reduce-top-space");
        } else {
            document
                .getElementsByClassName("input-container")[0]
                ?.classList.remove("reduce-top-space");
        }

        setInputContent(paramValue);
        onSubmitCallback(paramValue);
    }, [location.search]);

    const fillInputField = (event) => {
        event.preventDefault();
        setInputContent(event.target.dataset.url);

        history(`?opensea-link=${event.target.dataset.url}`);
        // navigate(`?input=${inputValue}`);
    };

    const handleSubmit = (event) => {
        const queryValue = event.target[0].value;
        console.log("handleSubmit set input URL: " + queryValue);
        event.preventDefault();

        onSubmitCallback(queryValue);
        setQuery(queryValue);
        history(`?opensea-link=${queryValue}`);

        if (!!queryValue) {
            document
                .getElementsByClassName("input-container")[0]
                ?.classList.add("reduce-top-space");
        }
    };

    const handleOnChange = (event) => {
        setInputContent(event.target.value);
    };

    return (
        <div className="InputZone core-width">
            <div className="input-container">
                <div className="input-content">
                    <form onSubmit={handleSubmit}>
                        <label>OpenSea URL:&nbsp;&nbsp;&nbsp;</label>
                        <input
                            ref={inputName}
                            className="container"
                            type="text"
                            id="input"
                            value={inputContent}
                            onChange={handleOnChange}
                            onSubmit={handleSubmit}
                        />
                        <button type="submit" onKeyUp={handleSubmit}>
                            {" "}
                            Submit{" "}
                        </button>
                    </form>
                </div>

                <p className="very-small test-links">
                    <br />
                    Test with:{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/9998"
                    >
                        CryptoPunk #9998
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/6723"
                    >
                        BAYC SD #6723
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0x06012c8cf97bead5deae237070f9587f8e7a266d/999999"
                    >
                        KryptoKitty #999999
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0xed5af388653567af2f388e6224dc7c4b3241c544/1338"
                    >
                        Azuki #1338
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://testnets.opensea.io/assets/goerli/0x4f4a5b4718db66e92d6ee19762b6c3a2bb18a761/31"
                    >
                        Beligent NFT
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0/25750"
                    >
                        Verisart certified
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/93146577784668714140459221259964172865612003863722902766963240036075040669697"
                    >
                        TokenArt
                    </a>{" "}
                    -{" "}
                    <a
                        onClick={fillInputField}
                        href="#"
                        data-url="https://opensea.io/assets/ethereum/0xeaa4c58427c184413b04db47889b28b5c98ebb7b/129"
                    >
                        NFTree #129
                    </a>
                </p>
            </div>
        </div>
    );
};

InputZone.propTypes = {};

InputZone.defaultProps = {};

export default InputZone;
