/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect, useState } from "react";
import "./ResultZone.scss";

import { isIpfsUrl } from "./../../../utils/utils.js";

// Props can deliver a valid URL, an OpenSea URL or a contract address and token ID.
const ResultZone = ({ resultObject }) => {
    console.log(resultObject)
    const [imageUrl, setImageUrl] = useState("");
    const [svgData, setSvgData] = useState("");

    const [isCertificateOpen, setCertificateIsOpen] = useState(true);
    const [areDetailsOpen, setDetailsAreOpen] = useState(false);
    const [isMetadataOpen, setMetadataIsOpen] = useState(false);

    const toggleCertificate = (event) => {
        setCertificateIsOpen((current) => !current);
    };

    const toggleDetails = (event) => {
        setDetailsAreOpen((current) => !current);
    };

    const toggleMetadata = (event) => {
        setMetadataIsOpen((current) => !current);
    };

    const renderImage = (imageSrc: string) => {
        return (
            <div className="nft-block-img">
                <img src={imageSrc} />
            </div>
        );
    };

    const renderSvgImage = () => {
        return (
            <div
                className="nft-block-img"
                dangerouslySetInnerHTML={{ __html: svgData }}
            ></div>
        );
    };

    const renderNftMainData = () => {
        return (
            <div className="card">
                <div className="row mb-4">
                    <div className="col-4 text-muted">Name:</div>
                    <div className="col-8 nft-title">{nftData.nftName}</div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Collection:</div>
                    <div className="col-8 nft-collection">
                        {nftData.collection}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Floor price:</div>
                    <div className="col-8 nft-floor-price">
                        {nftData.floorPrice}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Description:</div>
                    <div className="col-8">{nftData.description}</div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Metadata storage:</div>
                    <div className="col-8">
                        {nftData.metadataStorageIsDecentralized
                            ? "Decentralized, safest"
                            : "Traditional web storage, potentially unsafe!"}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Image storage:</div>
                    <div className="col-8">
                        {nftData.imageStorageIsDecentralized
                            ? "Decentralized, safest"
                            : "Traditional web storage, potentially unsafe!"}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Links:</div>
                    <div className="col-8">OpenSea, Etherscan</div>
                </div>
            </div>
        );
    };

    const renderNftCertificate = () => {
        return (
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                isCertificateOpen
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            onClick={toggleCertificate}
                        >
                            Certificate
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={
                            isCertificateOpen
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row mb-4">
                                <div className="col-5 text-muted">
                                    Certificate creator address:
                                </div>
                                <div className="col-7">
                                    {resultObject["certificateData"]
                                        ? resultObject["certificateData"]
                                              .nftCreatorAddress
                                        : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-5 text-muted">
                                    Certificate creator name:
                                </div>
                                <div className="col-7">
                                    {resultObject["certificateData"]
                                        ? resultObject["certificateData"]
                                              .nftCreatorName
                                        : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-5 text-muted">
                                    Certificate timestamp:
                                </div>
                                <div className="col-7">
                                    {resultObject["certificateData"]
                                        ? resultObject["certificateData"]
                                              .timestamp +
                                          "(" +
                                          resultObject["certificateData"]
                                              .issueDate +
                                          ")"
                                        : "N/A"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNftDetails = () => {
        return (
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                areDetailsOpen
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            onClick={toggleDetails}
                        >
                            Details
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={
                            areDetailsOpen
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row mb-4">
                                <div className="col-3 text-muted">
                                    Contract address:
                                </div>
                                <div className="col-9 wrap-address">
                                    {details.contractAddress}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3 text-muted">
                                    Image address:
                                </div>
                                <div className="col-9 wrap-address">
                                    {nftData.nftImage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    /**
     * Returns a collapsible HTML section to display all the NFT data received from the Alchemy request.
     * @returns an HTML section to display all the data.
     */
    const renderAllNftData = () => {
        return (
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                isMetadataOpen
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            onClick={toggleMetadata}
                        >
                            Metadata
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={
                            isMetadataOpen
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row mb-4">
                                <code>
                                    <pre>
                                        {JSON.stringify(
                                            alchemyResponse,
                                            null,
                                            4
                                        )}
                                    </pre>
                                </code>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        console.log(resultObject)
        if (resultObject.imageUrl) {
            setImageUrl(resultObject.imageUrl);
            setSvgData("");
        } else if (resultObject.svgData) {
            setSvgData(resultObject.svgData);
            setImageUrl("");
        } else {
            console.log("No image to display");
        }
    }, [resultObject]);

    console.log(resultObject)
    const alchemyResponse = resultObject.alchemyResponse;

    const metadata = resultObject["alchemyResponse"]?.rawMetadata;
    const contract = alchemyResponse?.contract;

    const nftData = {
        nftName: alchemyResponse?.title
            ? alchemyResponse?.title
            : contract?.openSea.collectionName +
              " #" +
              alchemyResponse?.tokenId,
        collection: contract?.openSea.collectionName,
        description: metadata?.description,
        nftImage: metadata?.image
            ? metadata?.image
            : resultObject.imageUrl
            ? resultObject.imageUrl
            : contract?.openSea.imageUrl,
        imageIsSvg: resultObject.isSvg,
        imageStorageIsDecentralized: false,
        floorPrice: contract?.openSea.floorPrice
            ? `${contract?.openSea.floorPrice} ETH`
            : "N/A",
        contractAddress: contract?.address,
        metadataStorage:
            alchemyResponse?.tokenUri.gateway || alchemyResponse?.tokenUri.raw,
        metadataStorageIsDecentralized: false,
        tokenId: alchemyResponse?.tokenId,
    };
    nftData.metadataStorageIsDecentralized = isIpfsUrl(nftData.metadataStorage)
        ? true
        : false;
    nftData.imageStorageIsDecentralized =
        isIpfsUrl(metadata?.image) ||
        (nftData.imageIsSvg && nftData.metadataStorageIsDecentralized)
            ? true
            : false;

    const details = {
        contractAddress: contract?.address,
    };

    return (
        <div className="ResultZone">
            <div className="report-container">
                {(() => {
                    if (Object.keys(metadata).length !== 0) {
                        return (
                            <>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="center main-title blue-title very-large-text">{nftData.nftName}</span>
                                            {/* <h2>{nftData.collection}</h2> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            {imageUrl
                                                ? renderImage(imageUrl)
                                                : ""}
                                            {svgData ? renderSvgImage() : ""}
                                        </div>
                                        <div className="col-md-7">
                                            {renderNftMainData()}
                                            {renderNftDetails()}
                                            {renderNftCertificate()}
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {renderAllNftData()}
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    }
                })()}
            </div>
        </div>
    );
};

ResultZone.propTypes = {};

ResultZone.defaultProps = {};

export default ResultZone;
