/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./MainContent.scss";
import ContactButton from "../ContactButton/ContactButton/ContactButton";

const MainContent = ({ user }) => {
    // console.log("MainContent Component");
    // console.log(user);

    const refZ = useRef(null);
    useEffect(() => {
        refZ.current.className = "main-image unblur";
    }, []);

    return (
        <div className="MainContent">
            <div className="core-width">
                <div className="section1">
                    <div className="content-row">
                        <div className="starter">
                            <div className="headline blue-title huge-text">
                                The beautiful diligence
                            </div>
                            <div className="slogan large-text hide-below-768">
                                <div>
                                    <p>
                                        Link your asset to a digital and
                                        immutable certificate!
                                    </p>
                                    <p>
                                        Make the recognition of your asset easy,
                                        anytime, anywhere.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-image">
                            <div className="content-image">
                                <img
                                    className="nft-image"
                                    src="./green-check.png"
                                    ref={refZ}
                                ></img>
                            </div>
                        </div>
                    </div>
                    <div className="starter hide-from-768">
                        <div className="slogan very-large-text">
                            <p>
                                Link your asset to a digital and immutable
                                certificate!
                            </p>
                            <p>
                                Make the recognition of your asset easy,
                                anytime, anywhere.
                            </p>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />

                    <span className="divider"></span>
                    <ContactButton></ContactButton>
                    <span className="divider"></span>

                    {(() => {
                        // console.log(user);
                        if (user && user.publicAddress !== "") {
                            return (
                                <>
                                    <div className="cta-button-container cta-large-screen">
                                        <div>
                                            <Link to="/validator/">
                                                <button className="beligent-btn btn-semi-transparent btn-glow">
                                                    Check your NFT
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="cta-button-container cta-large-screen">
                                        <div>
                                            <Link to="/portal/">
                                                <button className="beligent-btn btn-semi-transparent btn-glow">
                                                    Access the portal
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    })()}
                    {(() => {
                        // console.log(user);
                        if (user && user.publicAddress !== "") {
                            return (
                                <>
                                    <div className="content-row">
                                        <div className="cta-button-container cta-small-screen">
                                            <div>
                                                <Link to="/validator/">
                                                    <button className="beligent-btn btn-semi-transparent btn-glow">
                                                        Check your NFT
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-row">
                                        <div className="cta-button-container cta-small-screen">
                                            <div>
                                                <Link to="/portal/">
                                                    <button className="beligent-btn btn-semi-transparent btn-glow">
                                                        Access the portal
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    })()}
                </div>
            </div>

            <div className="core-width">
                <div className="section4">
                    <div>
                        <span className="center main-title blue-title very-large-text">
                            How it works
                        </span>
                    </div>
                    <div className="section4-content">
                        <div className="s4-column s4-column1">
                            <div>
                                <div className="img-container">
                                    <img src="./search-symbol.png"></img>
                                </div>
                                <div className="s4-text">
                                    <span className="blue-title large-text subtitle">
                                        Register
                                    </span>
                                    <div>
                                        <p>
                                            Identify yourself and submit your
                                            asset's details.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="s4-column s4-column2 ">
                            <div>
                                <div className="img-container">
                                    <img src="./link-symbol.png"></img>
                                </div>
                                <div className="s4-text">
                                    <div className="blue-title large-text subtitle">
                                        Record
                                    </div>
                                    <div>
                                        <p>
                                            Our system records your item on the
                                            blockchain, ensuring an immutable
                                            record of authenticity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="s4-column s4-column3">
                            <div>
                                <div className="img-container">
                                    <img src="./check-symbol.png"></img>
                                </div>
                                <div className="s4-text">
                                    <div className="blue-title large-text subtitle">
                                        Verify
                                    </div>
                                    <div>
                                        <p>
                                            You digital certificate can be
                                            verified globally in seconds!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section3">
                <div className="core-width">
                    <div>
                        <span className="center main-title very-large-text">
                            Blockchain-powered certificates
                        </span>
                    </div>
                    <div className="section3-container">
                        <div>
                            <div className="large-text subtitle">
                                Unparalleled benefits
                            </div>
                            <div>
                                <p>
                                    Our on-chain certificates offer unparalleled
                                    security and convenience.
                                    <br />
                                    They eliminate the risk of counterfeit,
                                    reduce reliance on physical documentation,
                                    and provide a universally accessible and
                                    verifiable proof of authenticity.
                                </p>
                            </div>
                        </div>

                        <br />
                        <div>
                            <div className="large-text subtitle">
                                Bringing trust to various industries
                            </div>
                            <div>
                                <p>
                                    From art and collectibles to luxury goods
                                    and important documents, our blockchain
                                    certification process is adaptable to a wide
                                    range of industries, offering them a new
                                    standard in authenticity verification.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="core-width">
                <div className="section4">
                    <div>
                        <span className="center main-title blue-title very-large-text">
                            Benefits of on-chain authentication
                        </span>
                    </div>
                    <div className="section4-content">
                        <div className="block-spacing">
                            <div className="blue-title large-text subtitle">
                                Enhanced security
                            </div>
                            <div>
                                <p>
                                    With blockchain, each certificate is
                                    uniquely secure and impossible to
                                    counterfeit.
                                </p>
                            </div>
                        </div>
                        <div className="block-spacing">
                            <div className="blue-title large-text subtitle">
                                Global verification
                            </div>
                            <div>
                                <p>
                                    Authenticate your items from anywhere in the
                                    world, instantly.
                                </p>
                            </div>
                        </div>
                        <div className="block-spacing">
                            <div className="blue-title large-text subtitle">
                                Lifetime record
                            </div>
                            <div>
                                <p>
                                    Permanent record on the blockchain,
                                    providing lasting value and peace of mind.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <span className="divider"></span>
                <ContactButton></ContactButton>
                <span className="divider"></span>
                <br />
                <br />
                <br />
            </div>
        </div>
    );
};

MainContent.propTypes = {};

MainContent.defaultProps = {};

export default MainContent;
